import React from "react";
import styled from "styled-components";
import {
  layout,
  color,
  height,
  position,
  space,
  system,
  width,
  SpaceProps,
  PositionProps,
  SizeProps,
  LayoutProps,
  borders,
  BorderProps,
  ResponsiveValue,
} from "styled-system";

export * from "./CustomIcons";
export * from "./NotificationIcons";
export { FacebookSquare } from "styled-icons/boxicons-logos";
export { Hourglass } from "styled-icons/boxicons-regular";
export { Bookmarks, TShirt } from "styled-icons/boxicons-solid";
export { TwitterWithCircle } from "styled-icons/entypo-social";
export {
  ApplePay as BorderlessApplePay,
  Facebook as FAFacebook,
  Google,
  Instagram,
  Pinterest,
  Reddit,
  Youtube,
} from "styled-icons/fa-brands";
export { Keyboard, Newspaper, QuestionCircle } from "styled-icons/fa-regular";
export {
  CaretLeft,
  CaretUp,
  ChalkboardTeacher,
  Clock,
  Envelope,
  LayerGroup,
  Link,
  List,
  User,
} from "styled-icons/fa-solid";
export {
  ArrowLeft2 as ArrowLeft,
  ArrowRight2 as ArrowRight,
  ArrowDown2 as ArrowDown,
  Bubbles,
  Info,
  Location2 as Location,
  PriceTag,
} from "styled-icons/icomoon";
export { PlayCircle } from "styled-icons/fluentui-system-filled";
export {
  ArrowDropDown,
  ContentCopy,
  Fullscreen,
  FullscreenExit,
  People,
  Settings,
  Smartphone,
  Style,
  VideoLibrary,
} from "styled-icons/material";
export { Alert } from "styled-icons/octicons";
export { Edit2 } from "styled-icons/feather";

type CustomProps = {
  transition?: boolean;
  transform?: ResponsiveValue<string>;
  fill?: ResponsiveValue<string>;
  borderRadius?: ResponsiveValue<string | number>;
};

type StyledSystemProps = SpaceProps &
  LayoutProps &
  PositionProps &
  SizeProps &
  BorderProps;

type StyledDivProps = StyledSystemProps & CustomProps;

interface IconProps extends StyledDivProps {
  as?: React.ComponentType<any>;
  className?: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  ${layout}
  ${height}
  ${width}
  ${color}
  ${space}
  ${position}
  ${borders}
  ${system({
    transition: true,
    transform: true,
    fill: true,
    borderRadius: true,
  })}

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Icon = React.forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  const { as: Component, ...rest } = props;
  if (!Component) {
    return null;
  }

  return (
    <StyledDiv ref={ref} {...rest}>
      <Component />
    </StyledDiv>
  );
});

Icon.displayName = "Icon";

export default Icon;
