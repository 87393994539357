import React from "react";
import { InstantSearch, Index } from "react-instantsearch-dom";
import { searchClient } from "app/components/Algolia/constants";
import AutoComplete from "./AutoComplete";
import { AlgoliaIndexes } from "../Algolia/types";
import MobileAutoComplete from "./MobileAutoComplete";

export function SearchBar({ useMobile }: { useMobile: boolean }) {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={AlgoliaIndexes.classes}
    >
      <Index indexName={AlgoliaIndexes.instructors} />
      <Index indexName={AlgoliaIndexes.programs} />
      {useMobile ? <MobileAutoComplete /> : <AutoComplete />}
    </InstantSearch>
  );
}
