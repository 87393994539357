import React from "react";
import styled from "styled-components";
import { useLocation, matchPath } from "react-router";
import Flex from "app/components/Flex";
import { H7 } from "app/components/Typography";
import Icon from "app/components/Icon";

const Wrapper = styled(Flex)`
  ${({ hoverBg, theme }) =>
    hoverBg &&
    `
      @media (hover: hover) {
        :hover {
            background: ${theme.colors.monochrome[1]};
          }
        }
      }
  `}
`;

interface Props {
  ActiveLinkIcon?: React.ComponentType<any>;
  active?: boolean;
  children: React.ReactNode;
  goToLink(params: { path: string; component?: string }): void;
  LinkIcon: React.ComponentType<any>;
  path?: string;
}

const ShrunkenSideNavLink = ({
  ActiveLinkIcon,
  active = false,
  children,
  goToLink,
  path,
  LinkIcon,
  ...rest
}: Props) => {
  const location = useLocation();
  const isActive =
    active || matchPath(location.pathname, { path, exact: true });

  return (
    <Wrapper
      height="96px"
      width="100%"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb={2}
      onClick={() => path && goToLink && goToLink({ path })}
      color={isActive ? "monochrome.7" : "monochrome.5"}
      hoverBg={!isActive && "monochrome.2"}
      {...rest}
    >
      <Icon
        height="24px"
        width="30px"
        as={isActive && ActiveLinkIcon ? ActiveLinkIcon : LinkIcon}
      />
      {children}
    </Wrapper>
  );
};

export default ShrunkenSideNavLink;
