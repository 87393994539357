import React from "react";
import { InstructorResultItem } from "./InstructorResultItem";
import { ClassResultItem } from "./ClassResultItem";
import { ProgramResultItem } from "./ProgramResultItem";
import {
  AlgoliaIndexes,
  AlgoliaInstructorData,
  AlgoliaClassData,
  AlgoliaProgramData,
} from "../Algolia/types";

interface Props {
  hit: AlgoliaInstructorData | AlgoliaClassData | AlgoliaProgramData;
  indexName: AlgoliaIndexes;
}

export function SearchResultItem({ hit, indexName }: Props) {
  switch (indexName) {
    case AlgoliaIndexes.instructors:
      return <InstructorResultItem hit={hit as AlgoliaInstructorData} />;
    case AlgoliaIndexes.classes:
      return <ClassResultItem hit={hit as AlgoliaClassData} />;
    case AlgoliaIndexes.programs:
      return <ProgramResultItem hit={hit as AlgoliaProgramData} />;
    default:
      return null;
  }
}
