import React, { useState } from "react";
import styled from "styled-components";
import {
  LayoutProps,
  BorderProps,
  SpaceProps,
  border,
  layout,
  space,
  system,
} from "styled-system";
import styles from "./styles.module.scss";

interface ImgWrapperProps extends BorderProps, LayoutProps, SpaceProps {
  objectFit?: string;
}

const ImgWrapper = styled.img<ImgWrapperProps>`
  ${layout}
  ${border}
  ${space}
  ${system({
    objectFit: true,
  })}
`;

const LoaderDiv = styled.div<ImgWrapperProps>`
  ${layout}
  ${border}
  ${space}
  display: inline-block;
  position: relative;
  background: inherit;
  border-radius: inherit;
`;

export interface ImgProps extends ImgWrapperProps {
  src: string;
  placeholderSrc?: string;
  alt?: string;
}

const Img: React.FC<ImgProps> = ({
  placeholderSrc,
  src,
  alt,
  ...rest
}: ImgProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [imageSrc, setImageSrc] = useState(src);

  const isLoading = !isLoaded && !hasError;

  return (
    <>
      {isLoading && <LoaderDiv className={styles.loader} {...rest} />}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore - Allow styled-system responsive values for width/height */}
      <ImgWrapper
        src={imageSrc}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        onError={() => {
          if (placeholderSrc) {
            setImageSrc(placeholderSrc);
          }
          setHasError(true);
        }}
        {...rest}
      />
    </>
  );
};

export default Img;
