export interface AlgoliaInstructorData {
  credits: string[];
  name: string;
  slug: string;
  styles: string[];
  bio: string;
  location: string | null;
  social: {
    facebook: string | null;
    instagram: string | null;
    twitter: string | null;
    youtube: string | null;
    tiktok: string | null;
  };
  classes: Array<{
    [key: string]: {
      id: number;
      publishDate: string;
    };
  }>;
  listImagePath: string;
  username: string;
  objectID: string;
}

export interface AlgoliaClassData {
  accessType: string;
  categories: string[];
  credits: string;
  duration_group: string;
  duration_in_seconds: number;
  duration: string;
  explicit: boolean;
  hidden: boolean;
  id: string;
  instructor_name: string;
  instructor_slug: string;
  instructorUsername: string;
  isFamilyFriendly: boolean;
  isFree: boolean;
  level: string;
  objectID: string;
  preview_url: string;
  publish_date: string;
  slug: string;
  studioClassId: string;
  style_slug: string;
  style: string;
  styles_query: object;
  styles: string[];
  tags: object;
  thumbnail: string;
  title: string;
  type: string;
}

export interface AlgoliaProgramData {
  about_instructor: string;
  benefits: string[];
  categories: string[];
  description: string;
  difficulty: string;
  duration_in_seconds: number;
  instructor_slug: string;
  isFree: boolean;
  level: string[];
  model_name: string;
  preview_url: string;
  publish_date: string;
  recommendations: string[];
  slug: string;
  style: string;
  style_slug: string;
  subtitle: string;
  title: string;
  total_classes: number;
  total_moves: number;
  objectID: string;
}

export interface AlgoliaClassMultiRanges {
  duration_in_seconds: string;
}

export interface AlgoliaClassRefinements {
  level: string[];
  style: string[];
  type: string[];
  instructor_name: string[];
}

export interface DurationItem {
  label: string;
  start?: number;
  end?: number;
}

export interface SortByItem {
  value: string;
  label: string;
}

export enum AlgoliaIndexes {
  classes = "classes",
  classesByDurationAsc = "classes_by_duration_asc",
  classesByDurationDesc = "classes_by_duration_desc",
  classesByPublishDateAsc = "classes_by_publish_date_asc",
  classesByPublishDateDesc = "classes_by_publish_date_desc",
  classesByPopularityRankingAsc = "classes_by_popularity_ranking_asc",
  classesByTrendingRankingAsc = "classes_by_trending_ranking_asc",
  programs = "programsV2",
  programsByPublishDateDesc = "programsV2_by_publishDate_desc",
  programsByPublishDateAsc = "programsV2_by_publishDate_asc",
  instructors = "instructors",
}
