import styled, { css } from "styled-components";
import { color, fontSize, space } from "styled-system";
import Text from "app/components/Text";
import {
  FONT_SIZES,
  LETTER_SPACING,
  FONT_WEIGHTS,
  TEXT_TRANSFORM,
} from "./constants";

// DEPRECATED
// TODO: Replace with design system located at bottom

const sharedStyles = css`
  ${color};
  ${space};
  ${fontSize};
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: normal;
  text-transform: uppercase;
  ${sharedStyles};
`;

Title.defaultProps = {
  fontSize: 4,
};

export const Jumbo = styled.h1`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: normal;
  text-transform: uppercase;
  ${sharedStyles};
`;

Jumbo.defaultProps = {
  fontSize: 7,
};

export const Display = styled.h1`
  font-weight: bold;
  ${sharedStyles};
`;

Display.defaultProps = {
  fontSize: 6,
};

export const Headline = styled.h2`
  font-weight: bold;
  ${sharedStyles};
`;

Headline.defaultProps = {
  fontSize: 5,
};

export const Subheader = styled.h3`
  font-weight: bold;
  ${sharedStyles};
`;

Subheader.defaultProps = {
  fontSize: 3,
};

export const Body = styled.p`
  font-weight: normal;
  ${sharedStyles};
`;

Body.defaultProps = {
  fontSize: 3,
};

export const Caption = styled.p`
  font-weight: normal;
  ${sharedStyles};
`;

Caption.defaultProps = {
  fontSize: 2,
};

export const Small = styled.small`
  font-weight: normal;
  ${sharedStyles};
`;

Small.defaultProps = {
  fontSize: 0,
};

const ctaStyle = css`
  font-weight: bold;
  letter-spacing: 2px;
  ${sharedStyles};
`;

export const smallCtaStyle = css`
  font-size: ${props => props.theme.fontSizes[1]};
  ${ctaStyle}
  ${sharedStyles};
`;

export const mediumCtaStyle = css`
  font-size: ${props => props.theme.fontSizes[2]};
  ${ctaStyle}
  ${sharedStyles};
`;

export const largeCtaStyle = css`
  font-size: ${props => props.theme.fontSizes[3]};
  ${ctaStyle}
  ${sharedStyles};
`;

// DESIGN SYSTEM

export const H1 = styled(Text).attrs(props => ({
  as: "h1",
  fontSize: FONT_SIZES.H1,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H1Semi = styled(Text).attrs(props => ({
  as: "h1",
  fontSize: FONT_SIZES.H1,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H1P5 = styled(Text).attrs(props => ({
  as: "h1",
  fontSize: FONT_SIZES.H1P5,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H1P5Semi = styled(Text).attrs(props => ({
  as: "h1",
  fontSize: FONT_SIZES.H1P5,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H2 = styled(Text).attrs(props => ({
  as: "h2",
  fontSize: FONT_SIZES.H2,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H2Semi = styled(Text).attrs(props => ({
  as: "h2",
  fontSize: FONT_SIZES.H2,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H2P5 = styled(Text).attrs(props => ({
  as: "h2",
  fontSize: FONT_SIZES.H2P5,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H2P5Semi = styled(Text).attrs(props => ({
  as: "h2",
  fontSize: FONT_SIZES.H2P5,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H3 = styled(Text).attrs(props => ({
  as: "h3",
  fontSize: FONT_SIZES.H3,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H3Semi = styled(Text).attrs(props => ({
  as: "h3",
  fontSize: FONT_SIZES.H3,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H4 = styled(Text).attrs(props => ({
  as: "h4",
  fontSize: FONT_SIZES.H4,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H4Semi = styled(Text).attrs(props => ({
  as: "h4",
  fontSize: FONT_SIZES.H4,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H5 = styled(Text).attrs(props => ({
  as: "h5",
  fontSize: FONT_SIZES.H5,
  letterSpacing: LETTER_SPACING.H5,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const H5Semi = styled(Text).attrs(props => ({
  as: "h5",
  fontSize: FONT_SIZES.H5,
  letterSpacing: LETTER_SPACING.H5,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H6 = styled(Text).attrs(props => ({
  as: "h6",
  fontSize: FONT_SIZES.H6,
  fontWeight: FONT_WEIGHTS.MEDIUM,
  ...props,
}))``;

export const H6Semi = styled(Text).attrs(props => ({
  as: "h6",
  fontSize: FONT_SIZES.H6,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  ...props,
}))``;

export const H7 = styled(Text).attrs({
  as: "p",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "9px",
  lineHeight: "15px",
  letterSpacing: "0.1em",
})``;

export const pStyling = {
  fontSize: "14px",
  letterSpacing: "0.04em",
  lineHeight: "160%",
};

export const P = styled(Text).attrs(props => ({
  as: "p",
  ...pStyling,
  ...props,
}))``;

export const P1 = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P1,
  fontWeight: FONT_WEIGHTS.REGULAR,
  ...props,
}))``;

export const P1Bold = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P1,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const P1Link = styled(P1).attrs({
  as: "a",
  textDecoration: "underline",
})``;

P1Link.defaultProps = {
  color: "white",
};

export const P2 = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P2,
  fontWeight: FONT_WEIGHTS.REGULAR,
  ...props,
}))``;

export const P2Bold = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P2,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const P2Link = styled(P2).attrs({
  as: "a",
  textDecoration: "underline",
})``;

P2Link.defaultProps = {
  color: "white",
};

export const A = styled(Text).attrs({
  as: "a",
  fontSize: "14px",
  letterSpacing: "0.04em",
  lineHeight: "160%",
  textDecoration: "underline",
})``;

export const P3 = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P3,
  letterSpacing: LETTER_SPACING.P3,
  fontWeight: FONT_WEIGHTS.REGULAR,
  ...props,
}))``;

export const P3Bold = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.P3,
  letterSpacing: LETTER_SPACING.P3,
  fontWeight: FONT_WEIGHTS.BOLD,
  ...props,
}))``;

export const BodySmall = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.BODY_SMALL,
  fontWeight: FONT_WEIGHTS.REGULAR,
  ...props,
}))``;

export const TextCaption = styled(Text).attrs(props => ({
  as: "p",
  fontSize: FONT_SIZES.CAPTION,
  fontWeight: FONT_WEIGHTS.REGULAR,
  ...props,
}))``;

export const Button = styled(Text).attrs(props => ({
  as: "span",
  fontSize: FONT_SIZES.BUTTON,
  fontWeight: FONT_WEIGHTS.BOLD,
  textTransform: TEXT_TRANSFORM.UPPERCASE,
  letterSpacing: LETTER_SPACING.BUTTON,
  ...props,
}))``;

export const SmallButton = styled(Text).attrs(props => ({
  as: "span",
  fontSize: FONT_SIZES.SMALL_BUTTON,
  fontWeight: FONT_WEIGHTS.SEMI_BOLD,
  textTransform: TEXT_TRANSFORM.UPPERCASE,
  ...props,
}))``;

export const LimitLineText = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp || 1};
  -webkit-box-orient: vertical;
`;
