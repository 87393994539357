import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { InstructorAvatar } from "app/components/InstructorAvatar";
import { P1, P2 } from "app/components/Typography";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSearchBarAction } from "modules/components";
import { AccountMode } from "services/typewriter/segment";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import styles from "./styles.module.scss";
import { AlgoliaInstructorData } from "../Algolia/types";

interface Props {
  hit: AlgoliaInstructorData;
}

export function InstructorResultItem({ hit }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFamilyModeOn } = useFamilyModeSetting();

  const handleClick = () => {
    dispatch(
      toggleSearchBarAction({
        toggle: false,
        accountMode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
      })
    );

    if (hit.username) {
      history.push(`/@${hit.username}`);
    } else {
      history.push(`/instructors/${hit.slug}`);
    }
  };

  return (
    <div className={styles.searchResultItem} onClick={handleClick}>
      <div className={styles.instructorSection}>
        <InstructorAvatar
          instructorSlug={hit.slug}
          width={20}
          showName={false}
        />
        <div className={styles.instructorInfo}>
          <P1 className={styles.title}>
            <Highlight attribute="name" hit={hit} tagName="mark" />
          </P1>
          {hit.username && (
            <P2 className={styles.instructorUsername}>
              @<Highlight attribute="username" hit={hit} tagName="mark" />
            </P2>
          )}
        </div>
      </div>
    </div>
  );
}
