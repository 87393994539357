import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { InstructorAvatar } from "app/components/InstructorAvatar";
import { P1, P2 } from "app/components/Typography";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSelectsClassAction } from "modules/content";
import { toggleSearchBarAction } from "modules/components";
import { AccountMode } from "services/typewriter/segment";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import styles from "./styles.module.scss";
import { AlgoliaClassData } from "../Algolia/types";

interface Props {
  hit: AlgoliaClassData;
}

export function ClassResultItem({ hit }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isFamilyModeOn } = useFamilyModeSetting();

  const handleClick = () => {
    dispatch(
      userSelectsClassAction({
        classData: hit,
        programData: null,
        queryParams: null,
        component: "SearchBar",
        element: "SearchBarSuggestion",
        redirectToRouteOnEnd: null,
        extraTrackingData: null,
        entryRoute: location.pathname,
        accountMode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
      })
    );

    dispatch(
      toggleSearchBarAction({
        toggle: false,
        accountMode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
      })
    );
  };

  return (
    <div className={styles.searchResultItem} onClick={handleClick}>
      <P1 className={styles.title}>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </P1>
      <P2 className={styles.subtitle}>
        {hit.style}
        <span>&nbsp;&#124;&nbsp;</span>
        <Highlight attribute="type" hit={hit} tagName="mark" />
      </P2>
      <div className={styles.instructorSection}>
        <InstructorAvatar
          instructorSlug={hit.instructor_slug}
          width={20}
          showName={false}
        />
        <div className={styles.instructorInfo}>
          <P2 className={styles.instructorName}>
            <Highlight attribute="instructor_name" hit={hit} tagName="mark" />
          </P2>
          {hit.instructorUsername && (
            <P2 className={styles.instructorUsername}>
              @
              <Highlight
                attribute="instructorUsername"
                hit={hit}
                tagName="mark"
              />
            </P2>
          )}
        </div>
      </div>
    </div>
  );
}
