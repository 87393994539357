import React from "react";
import {
  userSelectsClassAction,
  userSelectsProgramAction,
} from "modules/content";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { connectAutoComplete } from "react-instantsearch-dom";
import Icon, { ChevronDown } from "app/components/Icon";
import { toggleSearchBarAction } from "modules/components";
import { AccountMode } from "services/typewriter/segment";
import { SearchResultItem } from "./SearchResultItem";
import { FamilyContext } from "../AppProviders/FamilyContextProvider";

const INPUT_HEIGHT = 60;
const VIEW_ALL_HEIGHT = 60;

const MobileAutoCompleteWrapper = styled.div`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__section-container {
    background: ${({ theme }) => theme.colors.white};
  }

  .react-autosuggest__section-title {
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 20px;
    font-size: 16px;
    letter-spacing: 3px;
  }

  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
  }

  .react-autosuggest__suggestion {
    padding: 15px 20px;
    cursor: pointer;

    @media (hover: hover) {
      :hover {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  mark,
  .mark {
    background: none;
    font-weight: bold;
    padding: 0;
  }
`;

const Input = styled.input`
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  box-sizing: border-box;
  background: 0 0;
  padding: 0 20px;
  height: 40px;
  width: 100%;
  border: 0;
  padding-bottom: 5px;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  margin-right: 20px;
  position: fixed;
  left: 0;
  background: ${({ theme }) => theme.colors.lightGrey};
  width: 100vw;
  align-items: center;
  ${({ navbarHeight }) => `
    top: ${navbarHeight}px;
    height: ${navbarHeight}px;
  `}

  input {
    letter-spacing: 0.5px;
  }
`;

const SuggestionsContainer = styled.div`
  position: fixed;
  left: 0;
  top: 50px;
  width: 100vw;

  ${({ navbarHeight }) =>
    `
      top: ${navbarHeight + INPUT_HEIGHT}px;
  `};
`;

const ResultsContainer = styled.div`
  overflow: scroll;
  padding: 10px 0;

  background: ${({ theme }) => theme.colors.white};
  ${({ navbarHeight }) => `
    height: calc(100vh - ${navbarHeight + INPUT_HEIGHT}px);
  `}

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

const ViewAllWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 12px;
  height: ${VIEW_ALL_HEIGHT}px;
  cursor: pointer;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const renderSectionTitle = section =>
  section.hits.length ? section.index : null;

const getSectionSuggestions = section => section.hits;

const getSuggestionValue = hit => hit.title;

const renderSuggestion = hit => <SearchResultItem hit={hit} />;

class MobileAutoComplete extends React.Component {
  static contextType = FamilyContext;

  constructor(props) {
    super(props);

    this.state = {
      value: props.currentRefinement,
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.searchQuery = this.searchQuery.bind(this);
  }

  componentDidMount() {
    document.body.className = "modal-open";
  }

  componentDidUpdate(prevProps) {
    const { showSearchBar } = this.props;
    if (!showSearchBar && prevProps.showSearchBar) {
      this.setState({ value: "" });
    }
  }

  componentWillUnmount() {
    document.body.className = "";
  }

  onKeyDown(event) {
    if (event.keyCode === 13 && !this.suggestionSelected) {
      this.searchQuery();
      event.target.blur();
    }

    this.suggestionSelected = false;
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionSelected = (event, data) => {
    const {
      userSelectsClass,
      userSelectsProgram,
      toggleSearchBar,
      location,
    } = this.props;
    const { isFamilyModeOn } = this.context;

    this.suggestionSelected = true;
    const accountMode = isFamilyModeOn
      ? AccountMode.Family
      : AccountMode.Studio;

    if (data.suggestion.model_name === "class") {
      userSelectsClass({
        classData: data.suggestion,
        component: "SearchBar",
        element: "SearchBarSuggestion",
        entryRoute: location.pathname,
        accountMode,
      });
    } else {
      userSelectsProgram({
        programSlug: data.suggestion.slug,
        component: "SearchBar",
        element: "SearchBarSuggestion",
      });
    }

    toggleSearchBar({ toggle: false, accountMode });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { refine } = this.props;
    refine(value);
  };

  onSuggestionsClearRequested = () => {
    const { refine } = this.props;
    refine();
  };

  searchQuery() {
    const { value } = this.state;
    const { location, history, toggleSearchBar } = this.props;
    toggleSearchBar(false);

    if (location.pathname?.includes("/instructors")) {
      history.push(`/results/instructors?query=${value}`);
    } else if (location.pathname?.includes("/programs")) {
      history.push(`/results/programs?query=${value}`);
    } else {
      history.push(`/results/classes?query=${value}`);
    }
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { navbarHeight, showSearchBar } = this.props;
    if (!showSearchBar) {
      return null;
    }

    return (
      <SuggestionsContainer navbarHeight={navbarHeight} {...containerProps}>
        <ResultsContainer
          navbarHeight={query ? navbarHeight + VIEW_ALL_HEIGHT : navbarHeight}
        >
          {!!query && children}
        </ResultsContainer>
        {!!query && children && (
          <ViewAllWrapper onClick={this.searchQuery}>
            View All Results
            <Icon
              width="10px"
              color="white"
              transform="rotate(-90deg)"
              as={ChevronDown}
            />
          </ViewAllWrapper>
        )}
      </SuggestionsContainer>
    );
  };

  renderInputComponent(inputProps) {
    const { navbarHeight, showSearchBar } = this.props;

    if (!showSearchBar) {
      return null;
    }

    return (
      <InputWrapper navbarHeight={navbarHeight}>
        <Input name="search" type="text" {...inputProps} />
      </InputWrapper>
    );
  }

  render() {
    const { hits } = this.props;
    const { value } = this.state;

    const inputProps = {
      placeholder: "Search",
      onChange: this.onChange,
      value,
      onKeyDown: this.onKeyDown,
      autoFocus: true,
    };

    return (
      <MobileAutoCompleteWrapper>
        <Autosuggest
          suggestions={hits}
          alwaysRenderSuggestions
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          renderInputComponent={this.renderInputComponent}
          multiSection
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
        />
      </MobileAutoCompleteWrapper>
    );
  }
}

const mapStateToProps = ({ components }) => ({
  navbarHeight: components.Navbar.height,
  showSearchBar: components.SearchBar.toggled,
});

const mapDispatchToProps = {
  toggleSearchBar: toggleSearchBarAction,
  userSelectsClass: userSelectsClassAction,
  userSelectsProgram: userSelectsProgramAction,
};

MobileAutoComplete.defaultProps = {
  showSearchBar: false,
};

MobileAutoComplete.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  navbarHeight: PropTypes.number.isRequired,
  refine: PropTypes.func.isRequired,
  showSearchBar: PropTypes.bool,
  toggleSearchBar: PropTypes.func.isRequired,
  userSelectsClass: PropTypes.func.isRequired,
  userSelectsProgram: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(connectAutoComplete(MobileAutoComplete))
);
