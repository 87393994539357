// eslint-disable no-console
import { push } from "connected-react-router";
import { get, omitBy, isNil } from "lodash";
import queryString from "query-string";
import qs from "qs";
import moment from "moment";
import { gql } from "@apollo/client";
import {
  SEARCH_MODULE,
  LIBRARY_MODULE,
  INSTRUCTOR_CONTAINER,
  ANALYTICS_LOCATION,
  HOME_CONTAINER,
} from "constants/";
import { getFiltersApplied } from "helpers/getFiltersApplied";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import {
  contentPreviewClass,
  contentPreviewProgram,
  contentSelectCategory,
  contentSelectClass,
  contentSelectProgram,
  homeSelectContent,
  librarySelectContent,
  searchSelectContent,
} from "../services/typewriter/segment";
import { enterContinuity } from "./continuity";

export const USER_START_PREVIEWING_CONTENT =
  "content/USER_START_PREVIEWING_CONTENT";
export const USER_START_PREVIEWING_PROGRAM =
  "content/USER_START_PREVIEWING_PROGRAM";
export const USER_PREVIEWING_CONTENT = "content/USER_PREVIEWING_CONTENT";
export const USER_STOP_PREVIEWING_CONTENT =
  "content/USER_STOP_PREVIEWING_CONTENT";

const initialState = {
  previewingId: null,
  contentData: {},
  component: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_PREVIEWING_CONTENT:
      return {
        ...state,
        previewDuration: action.previewDuration,
      };

    case USER_START_PREVIEWING_CONTENT:
      return {
        ...state, // To prevent user from previewing both programs and classes on same page
        previewingProgramSlug: null,
        previewingId: action.contentData.id,
        contentData: action.contentData,
        component: action.component,
      };

    case USER_START_PREVIEWING_PROGRAM:
      return {
        ...state,
        previewingProgramSlug: action.contentData.slug,
        contentData: action.contentData,
        component: action.component,
      };

    case USER_STOP_PREVIEWING_CONTENT:
      return initialState;

    default:
      return state;
  }
};

const trackClassSelectContent = ({
  location,
  classData,
  component,
  accountMode,
}) => {
  const prevPath = location.state?.prevPath;
  const selectContentData = {
    content_name: classData.title,
    content_type: "class",
    content_id: classData.id,
    account_mode: accountMode,
  };
  const { query, refinementList } = qs.parse(location.search.slice(1));

  if (location.pathname?.includes("/results/classes") && query?.length > 1) {
    searchSelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.search,
      module: SEARCH_MODULE.search_classes,
      search_text: query,
      container: null, // should be titled carousel/list, which does not exist currently
      element_type: "class_card",
      featured: false,
    });
  } else if (
    location.pathname?.includes("/library/classes") ||
    prevPath === "/library/classes"
  ) {
    librarySelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_classes,
      container: "classes",
      filters_applied: getFiltersApplied({ refinementList }),
      element_type: "class_card",
      featured: false,
    });
  } else if (location.pathname?.includes("/results/categories")) {
    librarySelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_categories,
      filters_applied: getFiltersApplied({ refinementList }),
      element_type: "class_card",
      featured: false,
    });
  } else if (prevPath === "/library/instructors") {
    const container = INSTRUCTOR_CONTAINER[component];
    librarySelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_instructors,
      container,
      element_type: "class_card",
      featured: container === "featured_class",
    });
  } else if (location.pathname?.includes("/dashboard")) {
    const container = HOME_CONTAINER[component];
    const elementType =
      container === "featured_content" ? "featured_card" : "class_card";

    homeSelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.home,
      container: HOME_CONTAINER[component],
      element_type: elementType,
      featured: container === "featured_content",
      module: null, // passing empty per https://stzy.atlassian.net/wiki/spaces/SP/pages/145195071/Home+Redesign+v1+Analytics
    });
  }
};

const trackProgramSelectContent = ({ location, programData, accountMode }) => {
  const prevPath = location.state?.prevPath;
  const { query, refinementList } = qs.parse(location.search.slice(1));

  const selectContentData = {
    content_name: programData.title,
    content_type: "program",
    content_id: programData.slug,
    element_type: "program_card",
    featured: false,
    account_mode: accountMode,
  };

  if (
    location.pathname?.includes("/library/programs") ||
    prevPath === "/library/programs"
  ) {
    librarySelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.library,
      container: "programs",
      module: LIBRARY_MODULE.library_programs,
      filters_applied: getFiltersApplied({ refinementList }),
    });
  } else if (location.pathname?.includes("/results/categories")) {
    librarySelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_categories,
      filters_applied: getFiltersApplied({ refinementList }),
    });
  } else if (
    location.pathname?.includes("/results/programs") &&
    query?.length > 1
  ) {
    searchSelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.search,
      module: SEARCH_MODULE.search_programs,
      search_text: query,
      filters_applied: getFiltersApplied({ refinementList }),
    });
  } else if (location.pathname?.includes("/dashboard")) {
    homeSelectContent({
      ...selectContentData,
      location: ANALYTICS_LOCATION.home,
      container: "dance_programs",
      module: null, // passing empty per https://stzy.atlassian.net/wiki/spaces/SP/pages/145195071/Home+Redesign+v1+Analytics
    });
  }
};

const trackPreviewingAction = () => (dispatch, getState) => {
  const state = getState();
  const {
    content: { contentData, component, previewDuration },
    router: { location },
    user: { subscription },
  } = state;

  // @TODO?: Add canUserTakeProgram to back-end
  const isContentLockedForProgram =
    contentData.isFree === false && !subscription.activeSubscription;

  if (previewDuration > 3) {
    if (contentData.id && parseInt(contentData.id) >= 0) {
      const programTrackingData = contentData.program
        ? {
            program_slug: contentData.program.slug,
            program_title: contentData.program.title,
            program_level: contentData.program.difficulty,
            program_style: contentData.program.style,
          }
        : {};
      contentPreviewClass({
        class_id: contentData.id,
        duration: previewDuration,
        instructor: contentData.instructor_name || contentData.instructor.name,
        level: contentData.level,
        path: location.pathname,
        selected_from: component,
        style: contentData.style,
        title: contentData.title,
        type: contentData.type,
        categories: contentData.categories,
        content_locked: !contentData.canUserTakeClass,
        ...programTrackingData,
      });
    } else {
      contentPreviewProgram({
        path: location.pathname,
        program_slug: contentData.slug,
        level: contentData.difficulty,
        style: contentData.style,
        categories: contentData.categories,
        selected_from: component,
        duration: previewDuration,
        content_locked: isContentLockedForProgram,
      });
    }
  }

  dispatch({ type: USER_STOP_PREVIEWING_CONTENT });
};

const trackPreviewingProgramAction = () => (dispatch, getState) => {
  const state = getState();
  const {
    content: { contentData, component, previewDuration },
    router: { location },
    user: { subscription },
  } = state;
  // @TODO?: Add canUserTakeProgram to back-end
  const isContentLockedForProgram =
    contentData.isFree === false && !subscription.activeSubscription;

  if (previewDuration > 3) {
    contentPreviewProgram({
      path: location.pathname,
      program_slug: contentData.slug,
      level: contentData.difficulty,
      style: contentData.style,
      selected_from: component,
      duration: previewDuration,
      categories: contentData.categories,
      content_locked: isContentLockedForProgram,
    });
  }

  dispatch({ type: USER_STOP_PREVIEWING_CONTENT });
};

const resolveQueryParams = ({ classData, programData, queryParams }) => {
  const queryParamResult = {
    ...queryParams,
  };

  if (classData.refId) {
    queryParamResult.classRefId = classData.refId;
  } else if (programData?.slug) {
    queryParamResult.program = programData.slug;
  } else if (classData.programs) {
    // TODO: VERIFY CLASS + PROGRAMS 1-to-many or 1-to-1 relationship?
    // eslint-disable-next-line prefer-destructuring
    queryParamResult.program = classData.programs[0];
  }

  if (classData.playlistId) {
    queryParamResult.playlist = classData.playlistId;
  }

  return queryString.stringify(omitBy(queryParamResult, isNil));
};

export const userSelectsClassAction = ({
  classData,
  programData,
  queryParams,
  component,
  element,
  redirectToRouteOnEnd,
  extraTrackingData,
  entryRoute,
  accountMode,
}) => (dispatch, getState) => {
  const state = getState();
  const {
    auth,
    router: { location },
    continuity,
  } = state;

  if (!auth.isAnonymous) {
    const stringifiedParams = resolveQueryParams({
      classData,
      programData,
      queryParams,
    });
    const routeToPush = `/class/${classData.id}${
      stringifiedParams ? `?${stringifiedParams}` : ""
    }`;
    const programTrackingData = programData
      ? {
          program_slug: programData.slug,
          program_title: programData.title,
          program_level: programData.level,
          program_style: programData.style,
        }
      : {};

    // @TODO: if using hooks, use useSelectClassEvent
    contentSelectClass({
      location: getAnalyticsLocationFromPath(location.pathname),
      path: location.pathname,
      class_id: classData.id,
      title: classData.title,
      categories: classData.categories,
      selected_from: component,
      event_time: moment().toISOString(),
      content_locked: !classData.canUserTakeClass,
      account_mode: accountMode,
      ...programTrackingData,
      ...extraTrackingData,
    });

    trackClassSelectContent({ location, classData, component, accountMode });

    if (!continuity.entryRoute) {
      dispatch(enterContinuity({ entryRoute }));
    }

    dispatch(
      push(routeToPush, {
        refComponent: component,
        refElement: element,
        redirectToRouteOnEnd,
        fromApp: true,
      })
    );
  } else {
    dispatch(push("/register"));
  }
};

export const userSelectsProgramAction = ({
  programSlug,
  component,
  element,
  classId,
  classRefId,
}) => async (dispatch, getState, { apolloClient }) => {
  const state = getState();
  const {
    auth,
    router: { location },
    user: { private: userPrivate },
  } = state;

  if (!auth.isAnonymous) {
    const route = classId
      ? `/class/${classId}?program=${programSlug}&classRefId=${classRefId}`
      : `/programs/${programSlug}`;

    try {
      const { data } = await apolloClient.query({
        variables: {
          slug: programSlug,
        },
        query: gql`
          query getProgramV2EventData($slug: String!) {
            getProgramV2(slug: $slug) {
              slug
              title
              level
              style
              categories
            }
          }
        `,
      });
      const { getProgramV2: programData } = data;

      trackProgramSelectContent({ location, programData });

      contentSelectProgram({
        program_slug: programData.slug,
        program_title: programData.title,
        selected_from: component,
        level: programData.level,
        style: programData.style,
        categories: programData.categories,
      });

      dispatch(
        push(route, {
          refComponent: component,
          refElement: element,
        })
      );
    } catch (err) {
      console.log({ err });
    }
  } else if (
    get(userPrivate, "experiment.eOD") &&
    location.pathname === "/register"
  ) {
    const queryObj = queryString.parse(location.search);
    queryObj.step = parseInt(queryObj.step, 10) + 1;
    const params = `?${queryString.stringify(queryObj)}`;

    window.analytics.track("Click - Sign up", {
      component,
      path: location.pathname,
      action: "View Program",
    });

    dispatch(push(`/register${params}`));
  } else {
    dispatch(push("/register"));
  }
};

export const userClickedRouteAction = ({
  path,
  component,
}) => async dispatch => {
  if (!path) {
    return;
  }

  window.analytics.track("Click - Link", {
    destination: path,
    in_component: component,
    path,
  });

  dispatch(push(path));
};

export const userClickedExternalLinkAction = ({ url }) => async () => {
  window.analytics.track("Click - External Link", {
    url,
  });

  window.open(url, "blank");
};

export const userSelectsCategoryAction = ({
  component,
  categoryData,
  accountMode,
}) => async (dispatch, getState) => {
  const state = getState();
  const {
    auth,
    router: { location },
  } = state;

  if (location.pathname?.includes("/library/categories")) {
    librarySelectContent({
      content_name: categoryData.name,
      content_type: "category",
      content_id: categoryData.slug,
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_categories,
      container: "categories",
      element_type: "category_card",
      account_mode: accountMode,
    });
  }

  dispatch(
    push(`/results/categories/${categoryData.slug}`, {
      prevPath: location.pathname,
    })
  );

  if (!auth.isAnonymous) {
    contentSelectCategory({
      categories: [categoryData.slug || "all-categories"],
      event_time: new Date().toISOString(),
      module: component,
      path: location?.pathname,
    });
  }
};

export const userStopPreviewingContentAction = () => (dispatch, getState) => {
  const state = getState();
  const {
    content: { previewingId },
  } = state;

  if (previewingId) {
    dispatch(trackPreviewingAction());
  }

  dispatch({ type: USER_STOP_PREVIEWING_CONTENT });
};

export const userStartPreviewingContentAction = ({
  contentData,
  component,
}) => (dispatch, getState) => {
  const state = getState();
  const {
    content: { previewingId },
  } = state;

  if (
    previewingId &&
    (contentData.id !== previewingId || contentData.slug !== previewingId)
  ) {
    dispatch(trackPreviewingAction());
  }

  dispatch({
    type: USER_START_PREVIEWING_CONTENT,
    contentData,
    component,
  });
};

export const userStartPreviewingProgramAction = ({
  contentData,
  component,
}) => (dispatch, getState) => {
  const state = getState();
  const {
    content: { previewingProgramSlug },
  } = state;

  if (previewingProgramSlug && contentData.slug !== previewingProgramSlug) {
    dispatch(trackPreviewingProgramAction());
  }

  dispatch({
    type: USER_START_PREVIEWING_PROGRAM,
    contentData,
    component,
  });
};

export const userPreviewingContentAction = ({
  contentData,
  previewDuration,
  component,
}) => dispatch => {
  dispatch({
    type: USER_PREVIEWING_CONTENT,
    contentData,
    component,
    previewDuration,
  });
};
