export enum Routes {
  account = "/account",
  account__cancel = "/account/cancel",
  account__cancel_success = "/account/cancel-success",
  account__family = "/account/family",
  account__preferences = "/account/preferences",
  account__settings = "/account/settings",
  account__subscription = "/account/subscription",
  account__subscription__switch_plan = "/account/subscription/switch-plan",
  account__manage_notifications = "/account/manage-notifications",
  achievements = "/achievements",
  billing_past_due = "/billing-past-due",
  challenges = "/challenges",
  challenges__challengeId = "/challenges/:challengeId",
  challenges__post__id = "/challenges/post/:id",
  checkout = "/checkout",
  class = "/class",
  class__id = "/class/:id",
  class__id__preview = "/class/:id/preview",
  community = "/community",
  dashboard = "/dashboard",
  history = "/history",
  history__menu_option = "/history/:menuOption",
  history__all = "/history/all",
  history__incomplete = "/history/incomplete",
  history__complete = "/history/complete",
  instructors__slug = "/instructors/:instructorSlug",
  library = "/library",
  library__categories = "/library/categories",
  library__classes = "/library/classes",
  library__free_classes = "/library/free-classes",
  library__basic_classes = "/library/basic-classes",
  library__instructors = "/library/instructors",
  library__programs = "/library/programs",
  login = "/login",
  not_found = "/not-found",
  party = "/party",
  playlists = "/playlists",
  playlists__id = "/playlists/:playlistId",
  playlists__created = "/playlists/created",
  playlists__steezy = "/playlists/steezy",
  playlists__following = "/playlists/following",
  playlists__weekly_recommendations = "/playlists/weekly-recommendations",
  post = "/post",
  post__id = "/post/:id",
  profile = "/profile",
  profile__username = "/profile/:username",
  programs = "/programs",
  programs__slug = "/programs/:slug",
  register = "/register",
  results = "/results",
  results__categories = "/results/categories",
  results__categories__categorySlug__classes = "/results/categories/:categorySlug/classes",
  results__categories__categorySlug__programs = "/results/categories/:categorySlug/programs",
  results__classes = "/results/classes",
  results__free_classes = "/results/free-classes",
  results__basic_classes = "/results/basic-classes",
  results__instructors = "/results/instructors",
  results__programs = "/results/programs",
  saved = "/saved",
  saved__classes = "/saved/classes",
  saved__programs = "/saved/programs",
  saved__menu_option = "/saved/:menuOption",
  schedule = "/schedule",
  unlocked_classes = "/unlocked-classes",
  upgrade = "/upgrade",
  user_profile = "/@:username",
  user_profile_achievements = "/@:username/achievements",
  user_profile_classes = "/@:username/classes",
  user_profile_videos = "/@:username/videos",
}
