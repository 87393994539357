import { gql } from "@apollo/client";
import {
  CLASS_FIELDS,
  CORE_SUBSCRIPTION_FIELDS,
  CORE_PLAN_FIELDS,
} from "./fragments";

export const SET_EXPERIMENT_MUTATION = gql`
  mutation SetExperiment($experimentId: ID!) {
    setExperiment(input: { experimentId: $experimentId }) {
      experimentId
      variant
    }
  }
`;

export const CREATE_RECOMMENDED_SCHEDULE_PLAYLIST_MUTATION = gql`
  mutation CreateRecommendedSchedulePlaylist($date: String!) {
    createRecommendedSchedulePlaylistV2(input: { date: $date }) {
      id
    }
  }
`;

export const UPDATE_WEEKLY_STREAK_GOAL_MUTATION = gql`
  mutation UpdateWeeklyStreakGoal($streakGoal: Int!) {
    updateWeeklyStreakGoal(input: { streakGoal: $streakGoal }) {
      streakGoal
    }
  }
`;

export const CREATE_SCHEDULE_PLAYLIST_MUTATION = gql`
  mutation CreateSchedule($date: String!, $classIds: [ClassIdentifier!]!) {
    createSchedulePlaylistV2(input: { date: $date, classIds: $classIds }) {
      id
    }
  }
`;

export const UPDATE_SCHEDULE_PLAYLIST_MUTATION = gql`
  mutation UpdateSchedule($id: String!, $classIds: [ClassIdentifier!]!) {
    updateSchedulePlaylistV2(input: { id: $id, classIds: $classIds }) {
      id
      duration_in_seconds
      schedule_index

      progress {
        completed
        seen_completed_modal
      }

      classes {
        duration
        duration_in_seconds
        id
        level
        preview_url
        slug
        style
        thumbnail
        title
        type

        instructor {
          name
        }

        progress {
          completed
        }
      }
    }
  }
`;

export const ADD_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION = gql`
  mutation AddClassesForSchedulePlaylist(
    $id: String!
    $classIds: [ClassIdentifier!]!
    $fromModule: String!
  ) {
    playlist: addClassesForSchedulePlaylistV2(
      input: { id: $id, classIds: $classIds, fromModule: $fromModule }
    ) {
      id
      duration_in_seconds
      schedule_index

      progress {
        completed
        seen_completed_modal
      }

      classes {
        duration
        duration_in_seconds
        id
        level
        preview_url
        slug
        style
        thumbnail
        title
        type

        instructor {
          name
        }

        progress {
          completed
        }
      }
    }
  }
`;

export const REMOVE_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION = gql`
  mutation RemoveClassesForSchedulePlaylist(
    $id: String!
    $classIds: [ClassIdentifier!]!
    $fromModule: String!
  ) {
    playlist: removeClassesForSchedulePlaylistV2(
      input: { id: $id, classIds: $classIds, fromModule: $fromModule }
    ) {
      id
      duration_in_seconds
      schedule_index

      progress {
        completed
        seen_completed_modal
      }

      classes {
        duration
        duration_in_seconds
        id
        level
        preview_url
        slug
        style
        thumbnail
        title
        type

        instructor {
          name
        }

        progress {
          completed
        }
      }
    }
  }
`;

export const REMOVE_TAKEN_CLASS_MUTATION = gql`
  mutation RemoveTakenClass(
    $classIdentifier: ClassIdentifier!
    $date: String!
  ) {
    removeTakenClassV2(
      input: { classIdentifier: $classIdentifier, date: $date }
    ) {
      success
    }
  }
`;

export const START_CLASS_MUTATION = gql`
  ${CLASS_FIELDS}
  mutation StartClass(
    $classId: String!
    $startedDateTime: String!
    $context: Context
  ) {
    startClassV2(
      input: {
        classId: $classId
        startedDateTime: $startedDateTime
        context: $context
      }
    ) {
      ...ClassFields
    }
  }
`;

export const UPDATE_CLASS_PROGRESS_MUTATION = gql`
  ${CLASS_FIELDS}
  mutation UpdateClassProgress(
    $classId: String!
    $timestampSeconds: Int!
    $sessionSeconds: Int!
    $playedSeconds: [Int!]
    $playedDate: String
    $context: Context
  ) {
    updateClassProgressV2(
      input: {
        classId: $classId
        timestampSeconds: $timestampSeconds
        sessionSeconds: $sessionSeconds
        playedSeconds: $playedSeconds
        playedDate: $playedDate
        context: $context
      }
    ) {
      ...ClassFields
    }
  }
`;

export const UPDATE_CLASS_SESSION_MUTATION = gql`
  ${CLASS_FIELDS}
  mutation UpdateClassSession(
    $classId: String!
    $sessionSeconds: Int!
    $context: Context
  ) {
    updateClassSessionV2(
      input: {
        classId: $classId
        sessionSeconds: $sessionSeconds
        context: $context
      }
    ) {
      ...ClassFields
    }
  }
`;

export const COMPLETE_CLASS_MUTATION = gql`
  ${CLASS_FIELDS}
  mutation CompleteClass(
    $classId: String!
    $completedDateTime: String!
    $context: Context
  ) {
    completeClassV2(
      input: {
        classId: $classId
        completedDateTime: $completedDateTime
        context: $context
      }
    ) {
      ...ClassFields
    }
  }
`;

export const RATE_CLASS_MUTATION = gql`
  mutation RateClass($classId: Int!, $rating: Int!, $comment: String) {
    rateClass(
      input: { classId: $classId, rating: $rating, comment: $comment }
    ) {
      createdAt
    }
  }
`;

export const SET_USERS_TIMEZONE_MUTATION = gql`
  mutation SetUsersTimezone($zoneName: String!) {
    setUsersTimezone(input: { zoneName: $zoneName }) {
      success
    }
  }
`;

export const FOLLOW_CATEGORY_MUTATION = gql`
  mutation FollowCategory($slug: ID!, $isFollowing: Boolean!) {
    followCategory(input: { slug: $slug, isFollowing: $isFollowing }) {
      slug
      isFollowing
    }
  }
`;

export const FOLLOW_CATEGORIES_MUTATION = gql`
  mutation FollowCategories(
    $categories: [FollowingInput!]!
    $fromModule: String
  ) {
    followCategories(
      input: { categories: $categories, fromModule: $fromModule }
    ) {
      slug
      isFollowing
    }
  }
`;

export const TOGGLE_SAVE_PROGRAM_MUTATION = gql`
  mutation ToggleSaveProgram($programSlug: String!, $willSave: Boolean!) {
    toggleSaveProgram(
      input: { programSlug: $programSlug, willSave: $willSave }
    ) {
      slug
      isSaved
    }
  }
`;

export const TOGGLE_CLASS_COMPLETE_MUTATION = gql`
  mutation ToggleClassComplete(
    $programClassRefId: String!
    $isComplete: Boolean!
  ) {
    toggleClassComplete(
      input: { programClassRefId: $programClassRefId, isComplete: $isComplete }
    ) {
      slug
      isSaved
      progress {
        startedDate
        minutesSpent
        completedDate
        percentCompleted
        sectionsCompletedCount
        completedClassesCount
        queuedClass {
          id
          progress {
            completed
            started
            time {
              hour
              minute
              second
            }
            programClassProgress {
              programSlug
              isCompleted
            }
          }
          refId
          slug
        }
      }
      blocks {
        refId
        progress {
          isCompleted
          sectionsCompletedCount
          classesCompletedPercent
        }
        sectionsCount
        sortIndex
        superscript
        sections {
          refId
          progress {
            isCompleted
          }
          sortIndex
          title
          durationInMins
          classesCount
          classes {
            id
            progress {
              completed
              started
              time {
                hour
                minute
                second
              }
              programClassProgress {
                programSlug
                isCompleted
              }
            }
            refId
            slug
            isSaved
          }
        }
      }
    }
  }
`;

export const TOGGLE_SAVE_CLASS_MUTATION = gql`
  mutation ToggleSaveClass(
    $classId: String!
    $willSave: Boolean!
    $programClassRefId: String
  ) {
    toggleSaveClass(
      input: {
        classId: $classId
        willSave: $willSave
        programClassRefId: $programClassRefId
      }
    ) {
      id
      refId
      isSaved
    }
  }
`;

export const SUBSCRIPTION_CREATE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  mutation subscriptionCreate(
    $planInfo: PlanInfoInput!
    $gateway: GatewayInput!
    $paymentMethodType: PaymentMethodType!
    $trialOverrideTime: Timestamp
    $couponIds: [String!]
  ) {
    subscriptionCreate(
      input: {
        planInfo: $planInfo
        gateway: $gateway
        paymentMethodType: $paymentMethodType
        trialOverrideTime: $trialOverrideTime
        couponIds: $couponIds
      }
    ) {
      ...CoreSubscriptionFields
      trialEndTime
      plan {
        ...CorePlanFields
        trialPeriod
        periodUnit
        priceInCents
        provider {
          id
        }
      }
      user {
        id
        uid
        isActive
        hasTrialed
      }
    }
  }
`;

export const SUBSCRIPTION_UPGRADE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  mutation subscriptionUpgrade($subscriptionId: String!, $planId: String!) {
    subscriptionUpgrade(
      input: { subscriptionId: $subscriptionId, planId: $planId }
    ) {
      ...CoreSubscriptionFields
      plan {
        ...CorePlanFields
      }
    }
  }
`;

export const SUBSCRIPTION_REACTIVATE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  mutation subscriptionReactivate($subscriptionId: String!) {
    subscriptionReactivate(input: { subscriptionId: $subscriptionId }) {
      ...CoreSubscriptionFields
      plan {
        ...CorePlanFields
      }
      user {
        id
        isActive
      }
    }
  }
`;

export const SUBSCRIPTION_PAUSE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  mutation subscriptionPause($subscriptionId: String!, $resumeTime: DateTime!) {
    subscriptionPause(
      input: { subscriptionId: $subscriptionId, resumeTime: $resumeTime }
    ) {
      ...CoreSubscriptionFields
      isPauseScheduled
      nextBillingAt
      trialEndTime
      currentTermEnd
      pauseTime
      resumeTime
      plan {
        ...CorePlanFields
      }
      user {
        id
        isActive
      }
    }
  }
`;

export const SUBSCRIPTION_RESUME = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  mutation subscriptionResume($subscriptionId: String!) {
    subscriptionResume(
      input: { subscriptionId: $subscriptionId, shouldResumeNow: true }
    ) {
      ...CoreSubscriptionFields
      isPauseScheduled
      nextBillingAt
      trialEndTime
      currentTermEnd
      pauseTime
      resumeTime
      plan {
        ...CorePlanFields
      }
      user {
        id
        isActive
      }
    }
  }
`;

export const SUBSCRIPTION_EXTEND_TRIAL = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation subscriptionExtendTrial(
    $subscriptionId: String!
    $trialEndTime: DateTime!
  ) {
    subscriptionExtendTrial(
      input: { subscriptionId: $subscriptionId, trialEndTime: $trialEndTime }
    ) {
      ...CoreSubscriptionFields
      user {
        id
        isActive
      }
    }
  }
`;

export const USER_UPDATE_PAYMENT_SOURCE = gql`
  mutation userUpdatePaymentSource(
    $gateway: GatewayInput!
    $type: PaymentMethodType!
  ) {
    userUpdatePaymentSource(input: { gateway: $gateway, type: $type }) {
      id
      primaryPaymentSource {
        id
        gateway
        type
        card {
          lastFour
          brand
          fundingType
          object
        }
      }
    }
  }
`;

export const INVOICE_COLLECT_PAYMENT = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation invoiceCollectPayment(
    $invoiceId: String!
    $gateway: GatewayInput!
    $type: PaymentMethodType!
  ) {
    invoiceCollectPayment(
      input: { invoiceId: $invoiceId, gateway: $gateway, type: $type }
    ) {
      id
      amountDue
      dunningAttemptsCount
      nextRetryAt
      dueDate
      subscription {
        ...CoreSubscriptionFields
        user {
          id
          isActive
          primaryPaymentSource {
            id
            gateway
            type
            card {
              lastFour
              brand
              fundingType
              object
            }
          }
        }
      }
    }
  }
`;

export const SUBMIT_CANCELATION_FEEDBACK = gql`
  mutation SubmitCancelationFeedback($feedbackText: String!) {
    submitCancelationFeedback(input: { feedbackText: $feedbackText }) {
      success
    }
  }
`;

export const VIDEO_UPLOAD_CREATE = gql`
  mutation VideoUploadCreate($input: VideoUploadCreateInput!) {
    videoUploadCreate(input: $input) {
      id
      s3PartsUrls {
        partNumber
        url
      }
      uploadId
    }
  }
`;

export const USER_CLASS_UNLOCK = gql`
  mutation UserClassUnlock($classId: String!) {
    userClassUnlock(input: { classId: $classId }) {
      id
      uid
      remainingClassUnlocks
      unlockedClasses {
        id
        isUnlocked
      }
    }
  }
`;

export const VIDEO_UPLOAD_COMPLETE = gql`
  mutation VideoUploadComplete($input: VideoUploadCompleteInput!) {
    videoUploadComplete(input: $input) {
      id
    }
  }
`;

export const VIDEO_UPLOAD_SUBMIT = gql`
  mutation VideoUploadSubmit($input: VideoUploadSubmitInput!) {
    videoUploadSubmit(input: $input) {
      id
      studioClass {
        id
      }
      challenge {
        id
        isActive
      }
    }
  }
`;

export const SET_USERNAME = gql`
  mutation SetUsername($input: SetUsernameInput!) {
    userSetUsername(input: $input) {
      id
      username
    }
  }
`;

export const SET_PROFILE_PHOTO = gql`
  mutation setProfilePhoto($photoFile: Upload!) {
    userSetPhoto(input: { photoFile: $photoFile }) {
      photoURL
    }
  }
`;

export const REPORT_ACTIVITY = gql`
  mutation flaggedActivityCreate(
    $activityId: String!
    $activityType: ActivityType!
    $reportReason: ActivityReportReason!
    $description: String
  ) {
    flaggedActivityCreate(
      input: {
        activityId: $activityId
        activityType: $activityType
        reportReason: $reportReason
        description: $description
      }
    ) {
      activityId
    }
  }
`;
