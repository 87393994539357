import moment from "moment";
import _ from "lodash";
import zendesk from "helpers/zendesk";
import { onboardingCompleteAccountInfo } from "services/typewriter/segment";
import isServer from "helpers/isServer";
import { clearSession } from "helpers/session/clearSession";
import getTransactionId from "helpers/getTransactionId";
import env from "helpers/env";
import { getStoredGCLID } from "hooks/useGetGclid";
import { UpdateUsersLastSeenDocument } from "services/graphql";
import { AUTH_LOADING, AUTH_LOADED } from "../loading";
import { RESET_USER } from "../user/actions-types";
import {
  saveUserPublic,
  saveUserPrivateAction,
  loadAllUserData,
} from "../user/actions";
import {
  SET_AUTH,
  RESET_AUTH,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  RESET_ERROR,
} from "./actions-types";

const trackCompleteAccountInfoAction = ({
  email,
  provider,
  variant,
  module,
  class_id,
}) => (dispatch, getState) => {
  const state = getState();
  const { router, user } = state;
  const { location } = router;
  const trackObj = {
    auth_provider: provider,
    path: location.pathname,
    products: [
      {
        product_id: "1",
        price: 1,
        quantity: 1,
      },
    ],
    days_since_first_seen: Math.abs(
      // eslint-disable-next-line camelcase
      moment().diff(user.public?.first_seen, "days")
    ),
    gclid: getStoredGCLID(),
  };

  if (variant) {
    trackObj.variant = variant;
  }

  if (module) {
    trackObj.module = module;
  }

  if (class_id) {
    trackObj.class_id = class_id;
  }

  if (email) {
    trackObj.email = email;
  }

  /**
   * Context object required for impact radius integration
   * Docs: https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001173251-integrate-impact-as-a-destination-in-segment
   * https://app.clickup.com/t/2289103/GRTH-12
   */
  onboardingCompleteAccountInfo(trackObj, {
    context: {
      referrer: {
        type: "impactRadius",
        id: localStorage.getItem("irclickid") || null,
      },
    },
  });
};

export const setAuth = user => async (dispatch, getState, { getFirebase }) => {
  const currentDate = moment().toISOString();
  const firebase = getFirebase();
  const usersPublicSnapshot = await firebase
    .database()
    .ref(`users_public/${user.uid}`)
    .once("value");
  const usersPublic = usersPublicSnapshot.val();
  const firstName = _.get(usersPublic, "first_name", "");
  const lastName = _.get(usersPublic, "last_name", "");
  const name = firstName || lastName ? `${firstName} ${lastName}` : user.email;
  let firstSeen = null;

  dispatch({
    type: SET_AUTH,
    authData: user,
  });

  if (user.email) {
    dispatch(
      saveUserPrivateAction({
        email: user.email,
      })
    );
  }

  if (user.isAnonymous) {
    zendesk("webWidget", "hide");
    firstSeen = usersPublic?.first_seen || currentDate;
    dispatch(
      saveUserPublic({
        first_seen: firstSeen,
      })
    );
  }

  if (isServer) {
    return user;
  }

  const storedLocation = isServer
    ? {}
    : JSON.parse(localStorage.getItem("iplocation") || "{}");

  const identifyTraits = {
    name,
    first_name: firstName,
    last_name: lastName,
    email: user.email,
    is_anonymous: user.isAnonymous,
    first_seen: firstSeen,
    tz: _.get(storedLocation, "time_zone.id"),
    country_code: _.get(storedLocation, "country_code"),
    transaction_id: getTransactionId(),
    app_version: env("PUBLIC_VERSION"),
  };

  window.analytics.identify(user.uid, identifyTraits);

  return user;
};

export const loadAllAuthAndUser = user => async dispatch => {
  await dispatch(setAuth(user));
  await dispatch(loadAllUserData(user));
};

export const getAuth = () => (
  dispatch,
  getState,
  { getFirebase, apolloClient }
) => {
  const firebase = getFirebase();

  firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      const { uid, email, isAnonymous } = user;
      dispatch(loadAllAuthAndUser(user));
      if (isAnonymous) {
        // eslint-disable-next-line no-console
        console.log("Signed in anonymously:", uid);
      } else if (uid) {
        // eslint-disable-next-line no-console
        console.log("Signed in:", uid);
        zendesk("webWidget", "prefill", {
          email: {
            value: email,
            readOnly: true,
          },
        });
        // Update user's last seen timestamp
        try {
          await apolloClient.mutate({
            mutation: UpdateUsersLastSeenDocument,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error("Failed to update last seen:", err);
        }
      }
      dispatch({ type: AUTH_LOADED });
    } else {
      dispatch({ type: RESET_USER });
      dispatch({ type: RESET_AUTH });
      await firebase.auth().signInAnonymously();
    }
  });
};

export const login = credentials => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();

  dispatch({ type: AUTH_LOADING });

  await firebase.login(credentials).catch(error => {
    dispatch({ type: LOGIN_ERROR, error });
    dispatch({ type: AUTH_LOADED });
  });
};

export const validateEmailAction = (credentials, success) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  if (!credentials || (credentials && !credentials.email)) {
    throw new Error("Please fill out your email.");
  }

  const firebase = getFirebase();

  await firebase
    .auth()
    .fetchSignInMethodsForEmail(credentials.email)
    .then(providers => {
      if (!providers.length) {
        success();
      } else {
        throw new Error(
          "The email address is already in use by another account."
        );
      }
    })
    .catch(error => {
      throw error;
    });
};

export const linkCredentialsAction = (
  credentials,
  options = {},
  redirectOnSuccess
) => async (dispatch, getState, { getFirebase }) => {
  if (
    !credentials ||
    (credentials && (!credentials.email || !credentials.password))
  ) {
    throw new Error("Please fill out all fields.");
  }

  dispatch({ type: AUTH_LOADING });

  const firebase = getFirebase();
  const { currentUser } = firebase.auth(); // listens for auth object and live updates
  const { isAnonymous } = currentUser; // pull out boolean at the instance this function is called
  const credential = firebase.auth.EmailAuthProvider.credential(
    credentials.email,
    credentials.password
  );
  const currentDate = moment();

  await firebase
    .auth()
    .currentUser.linkWithCredential(credential)
    .then(async ({ user }) => {
      const { fullName } = credentials;
      const [firstName = null, lastName = null] = fullName
        ? fullName.split(" ")
        : [];
      const name =
        firstName || lastName ? `${firstName} ${lastName}` : credentials.email;

      user.sendEmailVerification();

      dispatch(
        saveUserPublic({
          last_login: currentDate.toISOString(),
          previous_login: currentDate.toISOString(),
          first_name: firstName,
          last_name: lastName,
        })
      );

      dispatch(
        saveUserPrivateAction({
          email: credentials.email,
          created_at: currentDate.toISOString(),
        })
      );

      window.analytics.identify(user.uid, {
        name,
        first_name: firstName,
        last_name: lastName,
        email: credentials.email,
        created_at: currentDate.toISOString(),
        last_login: currentDate.toISOString(),
        previous_login: currentDate.toISOString(),
        created_at_week: currentDate
          .clone()
          .startOf("isoWeek")
          .isoWeekday(1)
          .format("YYYY-MM-DD"),
        alias: user.uid,
        is_anonymous: user.isAnonymous,
      });

      await dispatch(loadAllAuthAndUser(user));
      if (isAnonymous) {
        dispatch(
          trackCompleteAccountInfoAction({
            provider: "email",
            variant: options.variant,
            module: options.module,
            class_id: options.class_id,
            email: credentials.email,
          })
        );
      }

      /*
        Calling redirectOnSuccess before AUTH_LOADED
        because UnauthenticatedRoute resolves auth before redirect can be called
      */
      if (redirectOnSuccess) {
        redirectOnSuccess();
      }
      dispatch({ type: AUTH_LOADED });
    })
    .catch(error => {
      dispatch({ type: AUTH_LOADED });
      throw error;
    });
};

export const createAccountWithProvider = user => async dispatch => {
  dispatch({ type: AUTH_LOADING });

  if (!!user.providerData && !!user.providerData[0]) {
    const displayName = user.providerData[0].displayName || "";
    const currentDate = moment();
    const [firstName] = displayName?.split(" ") || null;
    const [lastName] = displayName?.split(" ").reverse() || null;
    const name =
      firstName || lastName ? `${firstName} ${lastName}` : user.email;

    if (firstName || lastName) {
      dispatch(
        saveUserPublic({
          first_name: firstName,
          last_name: lastName,
        })
      );
    }

    dispatch(
      saveUserPrivateAction({
        email: user.email,
        created_at: currentDate.toISOString(),
      })
    );

    window.analytics.identify(user.uid, {
      name,
      first_name: firstName,
      last_name: lastName,
      email: user.email,
      created_at: currentDate.toISOString(),
      created_at_week: currentDate
        .clone()
        .startOf("isoWeek")
        .isoWeekday(1)
        .format("YYYY-MM-DD"),
      alias: user.uid,
    });
  }

  await dispatch(loadAllAuthAndUser(user));
  dispatch({ type: AUTH_LOADED });
};

export const disconnectProviderAction = providerKey => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise((resolve, reject) => {
    const firebase = getFirebase();
    dispatch({ type: AUTH_LOADING });

    const PROVIDERS = {
      apple: "apple.com",
      facebook: "facebook.com",
      google: "google.com",
    };

    return firebase
      .auth()
      .currentUser.unlink(PROVIDERS[providerKey])
      .then(authData => {
        dispatch({ type: AUTH_LOADED });
        resolve(authData);
      })
      .catch(error => {
        dispatch({ type: AUTH_LOADED });
        reject(error);
      });
  });

export const connectProviderAction = (
  providerKey,
  options = {},
  redirectOnSuccess
) => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  const { currentUser } = firebase.auth(); // listens for auth object and live updates
  const { isAnonymous } = currentUser; // pull out boolean at the instance this function is called
  const PROVIDERS = {
    apple: new firebase.auth.OAuthProvider("apple.com"),
    facebook: new firebase.auth.FacebookAuthProvider(),
    google: new firebase.auth.GoogleAuthProvider(),
  };

  dispatch({ type: AUTH_LOADING });

  await currentUser
    .linkWithPopup(PROVIDERS[providerKey])
    .then(result => {
      if (isAnonymous) {
        dispatch(createAccountWithProvider(result.user));
        dispatch(
          trackCompleteAccountInfoAction({
            provider: providerKey,
            variant: options.variant,
            module: options.module,
            class_id: options.class_id,
          })
        );
      } else {
        /*
          Calling redirectOnSuccess before AUTH_LOADED
          because UnauthenticatedRoute resolves auth before redirect can be called
        */
        if (redirectOnSuccess) {
          redirectOnSuccess();
        }
        dispatch({ type: AUTH_LOADED });
      }
    })
    .catch(error => {
      dispatch({ type: AUTH_LOADED });
      throw error;
    });
};

export const loginWithProvider = providerKey => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise(resolve => {
    dispatch({ type: AUTH_LOADING });
    const firebase = getFirebase();
    const PROVIDERS = {
      apple: new firebase.auth.OAuthProvider("apple.com"),
      facebook: new firebase.auth.FacebookAuthProvider(),
      google: new firebase.auth.GoogleAuthProvider(),
    };

    firebase
      .auth()
      .signInWithPopup(PROVIDERS[providerKey])
      .then(result => {
        dispatch({ type: AUTH_LOADED });
        resolve(result.user);
      })
      .catch(error => {
        dispatch({ type: LOGIN_ERROR, error });
        dispatch({ type: AUTH_LOADED });
      });
  });

export const logout = () => async (
  dispatch,
  getState,
  { getFirebase, apolloClient }
) => {
  // eslint-disable-next-line no-console
  console.log("logout");
  const firebase = getFirebase();

  // This event will cause Routes and children to unmount
  dispatch({ type: AUTH_LOADING });

  // Timeout lets us wait for unmounts to finish firing any
  // graphql mutations or authy calls
  try {
    await firebase.logout();
    clearSession();
    apolloClient.resetStore();
  } catch (err) {
    dispatch({ type: LOGOUT_ERROR, err });
    dispatch({ type: AUTH_LOADED });
  }
};

export const reauthenticateWithPopup = () => (
  dispatch,
  getState,
  { getFirebase }
) =>
  new Promise((resolve, reject) => {
    const firebase = getFirebase();
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .currentUser.reauthenticateWithPopup(provider)
      .then(() => {
        resolve();
      })
      .catch(err => reject(err));
  });

export const resetErr = () => dispatch =>
  dispatch({
    type: RESET_ERROR,
  });
