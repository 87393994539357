import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connectAutoComplete } from "react-instantsearch-dom";
import { ChevronDown, Close } from "app/components/Icon";
import IconButton from "app/components/Button/IconButton";
import { toggleSearchBarAction } from "modules/components";
import { AccountMode } from "services/typewriter/segment";
import { P1 } from "app/components/Typography";
import { SearchResultItem } from "./SearchResultItem";
import { FamilyContext } from "../AppProviders/FamilyContextProvider";
import styles from "./styles.module.scss";
import {
  AlgoliaClassData,
  AlgoliaIndexes,
  AlgoliaInstructorData,
  AlgoliaProgramData,
} from "../Algolia/types";

type Hit = AlgoliaInstructorData | AlgoliaClassData | AlgoliaProgramData;

interface AutoCompleteProps {
  hits: { index: AlgoliaIndexes; hits: Hit[] }[];
  currentRefinement: string;
  refine: (value: string) => void;
}

const SECTION_TITLES: { [key in AlgoliaIndexes]: string } = {
  [AlgoliaIndexes.instructors]: "INSTRUCTORS",
  [AlgoliaIndexes.classes]: "CLASSES",
  [AlgoliaIndexes.classesByDurationAsc]: "CLASSES",
  [AlgoliaIndexes.classesByDurationDesc]: "CLASSES",
  [AlgoliaIndexes.classesByPublishDateAsc]: "CLASSES",
  [AlgoliaIndexes.classesByPublishDateDesc]: "CLASSES",
  [AlgoliaIndexes.classesByPopularityRankingAsc]: "CLASSES",
  [AlgoliaIndexes.classesByTrendingRankingAsc]: "CLASSES",
  [AlgoliaIndexes.programs]: "PROGRAMS",
  [AlgoliaIndexes.programsByPublishDateDesc]: "PROGRAMS",
  [AlgoliaIndexes.programsByPublishDateAsc]: "PROGRAMS",
};

function AutoComplete({ hits, currentRefinement, refine }: AutoCompleteProps) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { isFamilyModeOn } = React.useContext(FamilyContext);
  const showSearchBar = useSelector(
    (state: any) => state.components.SearchBar.toggled
  );
  const hasResults = hits.some(indexHit => indexHit.hits.length > 0);

  useEffect(() => {
    if (!showSearchBar) {
      refine("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchBar, refine]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      searchQuery();
      (event.target as HTMLElement).blur();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refine(event.currentTarget.value);
  };

  const searchQuery = () => {
    if (location.pathname?.includes("/instructors")) {
      history.push(`/results/instructors?query=${currentRefinement}`);
    } else {
      history.push(`/results/classes?query=${currentRefinement}`);
    }

    dispatch(
      toggleSearchBarAction({
        toggle: false,
        accountMode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
      })
    );
  };

  return (
    <div className={styles.autoCompleteWrapper} data-visible={showSearchBar}>
      <div className={styles.inputWrapper}>
        {showSearchBar && (
          <input
            ref={inputRef}
            name="search"
            type="text"
            className={styles.input}
            placeholder="Search"
            value={currentRefinement}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        )}
        {showSearchBar && (
          <IconButton
            width="14px"
            Icon={Close}
            color="black"
            mr="5px"
            onClick={() =>
              dispatch(
                toggleSearchBarAction({
                  toggle: false,
                  accountMode: isFamilyModeOn
                    ? AccountMode.Family
                    : AccountMode.Studio,
                })
              )
            }
          />
        )}
      </div>
      {currentRefinement && (
        <div className={styles.suggestionsContainer}>
          <div className={styles.resultsContainer}>
            {showSearchBar &&
              hasResults &&
              hits.map(indexHit =>
                indexHit.hits.length > 0 ? (
                  <div key={indexHit.index} className={styles.section}>
                    <P1 fontWeight="bold" className={styles.sectionTitle}>
                      {SECTION_TITLES[indexHit.index]}
                    </P1>
                    {indexHit.hits.map(hit => (
                      <SearchResultItem
                        key={hit.objectID}
                        hit={hit}
                        indexName={indexHit.index}
                      />
                    ))}
                  </div>
                ) : null
              )}
          </div>
          {showSearchBar && hasResults && (
            <div className={styles.viewAllWrapper} onClick={searchQuery}>
              View All Results
              <ChevronDown
                width="10px"
                color="white"
                style={{ transform: "rotate(-90deg)" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default connectAutoComplete(AutoComplete);
