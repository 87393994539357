import React from "react";
import { Highlight } from "react-instantsearch-dom";
import { P1, P2 } from "app/components/Typography";
import { useDispatch } from "react-redux";
import { userSelectsProgramAction } from "modules/content";
import { toggleSearchBarAction } from "modules/components";
import { AccountMode } from "services/typewriter/segment";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { AlgoliaProgramData } from "../Algolia/types";
import styles from "./styles.module.scss";

interface Props {
  hit: AlgoliaProgramData;
}

export function ProgramResultItem({ hit }: Props) {
  const dispatch = useDispatch();
  const { isFamilyModeOn } = useFamilyModeSetting();

  const handleClick = () => {
    dispatch(
      userSelectsProgramAction({
        programSlug: hit.slug,
        component: "SearchBar",
        element: "SearchBarSuggestion",
        classId: null,
        classRefId: null,
      })
    );

    dispatch(
      toggleSearchBarAction({
        toggle: false,
        accountMode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
      })
    );
  };

  return (
    <div className={styles.searchResultItem} onClick={handleClick}>
      <P1 className={styles.title}>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </P1>
      {hit.style && <P2 className={styles.subtitle}>{hit.style}</P2>}
    </div>
  );
}
