import { gql } from "@apollo/client";
import { CORE_VIDEO_UPLOAD_FIELDS } from "./challenges/fragments";
import {
  CLASS_FIELDS,
  CORE_PLAN_FIELDS,
  CORE_PROFILE_FIELDS,
  CORE_PROGRAM_FIELDS,
  CORE_SUBSCRIPTION_FIELDS,
  PROGRAM_BLOCK_FIELDS,
  TRACKS_CORE_FIELDS,
} from "./fragments";

export const classCardFragment = gql`
  fragment ClassCardData on Class {
    duration
    duration_in_seconds
    id
    isFree
    isPreviewVideoMuted
    externalPreviewUrl
    level
    preview_url
    slug
    style
    thumbnail
    title
    type
    categories
    instructor {
      name
      slug
    }
    isExplicit
    progress {
      time {
        hour
        minute
        second
      }
      completed
    }
  }
`;

export const GET_EXPLORE_CATEGORIES_QUERY = gql`
  query GetExploreCategories($slugs: [ID!]!) {
    categories: getCategories(input: { slugs: $slugs }) {
      description
      isFollowing
      name
      programsV2 {
        slug
      }
      slug
      previewThumbnailUrl
      previewVideoUrl
      latestClasses {
        ...ClassCardData
      }
      shortClasses {
        ...ClassCardData
      }
      movesClasses {
        ...ClassCardData
      }
    }
  }
  ${classCardFragment}
`;

export const GET_CALENDAR_DATA_QUERY = gql`
  query GetCalendarData($startDate: String, $endDate: String) {
    calendarData: getCalendarData(
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      userCreatedPlaylists {
        id
        date
        classes {
          id
        }
      }
      dailyStreaks {
        id
        date
        achievedDailyStreakDate
      }
      weeklyStreakGoal
      weeklyStreakCount
    }
  }
`;

export const GET_ADDED_SCHEDULE_CLASSES = gql`
  query Playlist($date: String!) {
    addedClasses: getPlaylist(date: $date) {
      classes {
        id
        refId
      }
    }
  }
`;

export const GET_PLAYLIST_QUERY = gql`
  query Playlist($date: String!) {
    playlist: getPlaylist(date: $date) {
      id
      duration_in_seconds
      schedule_index

      progress {
        completed
        seen_completed_modal
      }

      classes {
        id
        studioClassId
        playlistId
        refId
        isFree
        isSaved
        duration
        duration_in_seconds
        level
        preview_url
        slug
        style
        thumbnail
        title
        type
        categories
        isUnlocked
        instructor {
          name
          slug
        }

        progress {
          completed
        }
      }

      takenClasses {
        id
        title
        slug
        thumbnail
        duration
        instructor {
          name
          slug
        }
        refId
      }
    }
  }
`;

export const GET_PROGRAM_V2_WITH_REF = gql`
  ${CORE_PROGRAM_FIELDS}
  query GetProgramV2WithRef($classRefId: String!) {
    getProgramV2WithRef: getProgramV2WithRef(
      input: { classRefId: $classRefId }
    ) {
      ...CoreProgramFields
      progress {
        startedDate
        minutesSpent
        completedDate
        percentCompleted
        sectionsCompletedCount
        completedClassesCount
      }
      instructor {
        name
        slug
      }
    }
  }
`;

export const GET_PROGRAM_V2_CORE = gql`
  ${CORE_PROGRAM_FIELDS}
  query GetProgramV2($slug: String!) {
    getProgramV2: getProgramV2(slug: $slug) {
      ...CoreProgramFields
      progress {
        startedDate
        minutesSpent
        completedDate
        percentCompleted
        sectionsCompletedCount
        completedClassesCount
      }
      instructor {
        name
        credits
        bio
      }
    }
  }
`;

export const GET_PROGRAM_V2_OVERVIEW = gql`
  ${PROGRAM_BLOCK_FIELDS}
  ${CORE_PROGRAM_FIELDS}
  query GetProgramV2($slug: String!) {
    getProgramV2: getProgramV2(slug: $slug) {
      ...CoreProgramFields
      progress {
        startedDate
        minutesSpent
        completedDate
        percentCompleted
        sectionsCompletedCount
        completedClassesCount
        queuedClass {
          ...ClassFields
        }
      }
      content {
        introduction {
          title
          description
        }
        competencies {
          title
          skills
        }
        needs {
          title
          description
        }
        assets {
          previewVideoURL
          thumbnailURL
          titleSvgURL
        }
      }
      instructor {
        name
        credits
        bio
        content {
          assets {
            avatarURL
            portraitURL
            landscapeURL
          }
        }
      }
      blocks {
        ...ProgramBlockFields
      }
      relatedClasses {
        duration
        duration_in_seconds
        id
        level
        instructor {
          name
          slug
        }
        preview_url
        progress {
          completed
          started
          time {
            hour
            minute
            second
          }
        }
        publish_date
        slug
        style
        type
        title
        thumbnail
        isFree
        isSaved
        categories
      }
    }
  }
`;

export const GET_CLASS = gql`
  ${CLASS_FIELDS}
  ${TRACKS_CORE_FIELDS}
  query GetClass(
    $classId: String!
    $programClassRefId: String
    $withProgramData: Boolean!
    $isSignedIn: Boolean!
  ) {
    getClass: getClass(
      input: { id: $classId, programClassRefId: $programClassRefId }
    ) {
      current {
        ...ClassFields
        isPurchased @include(if: $isSignedIn)
        songs {
          title
          artist
          isExplicit
        }
        tracks @include(if: $isSignedIn) {
          track {
            ...TracksCoreFields
          }
          startsAt
        }
        instructor {
          name
          slug
          bio
        }
        programMetadata @include(if: $withProgramData) {
          isLastClass
          block {
            refId
            title
            sortIndex
          }
          section {
            refId
            title
            sortIndex
          }
        }
        sections @include(if: $isSignedIn) {
          name
          cuepoints {
            name
            time
            timeSec
          }
        }
        video @include(if: $isSignedIn) {
          id
          hlsPlaybackUrls {
            defaultUrl
            frontUrl
            backUrl
          }
          drmHlsPlaybackUrls {
            defaultUrl
            default1080Url
            default720Url
            default540Url
            default360Url
            default360LowUrl
            backUrl
            frontUrl
            fairplayCertificateUrl
          }
          drmDashPlaybackUrls {
            defaultUrl
            backUrl
            frontUrl
          }
        }
      }
      nextProgramClass @include(if: $withProgramData) {
        id
        refId
        thumbnail
        title
      }
    }
  }
`;

export const GET_CLASSES = gql`
  query GetClasses(
    $freeOnly: Boolean
    $pagination: PaginationBody!
    $sort: SortBody
  ) {
    getClasses: getClasses(
      input: { freeOnly: $freeOnly, pagination: $pagination, sort: $sort }
    ) {
      classes {
        isFree
        duration
        duration_in_seconds
        id
        level
        preview_url
        slug
        style
        thumbnail
        title
        type
        categories
        instructor {
          name
          slug
        }

        progress {
          completed
        }
      }
      pageInfo {
        page
        totalPages
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SCHEDULE_QUERY = gql`
  query ScheduleQuery($date: String!) {
    schedule: schedule(date: $date) {
      id
      playlists {
        id
        date
        progress {
          completed
        }
      }
      progress {
        started
        completed
        seen_started_modal
        seen_completed_modal
      }
    }
  }
`;

export const GET_FREE_DAILY_CLASS_QUERY = gql`
  query GetFreeDailyClasses {
    freeDailyClasses: getFreeDailyClasses {
      ...ClassCardData
    }
  }
  ${classCardFragment}
`;

export const GET_SAVED_PROGRAMS = gql`
  query GetSavedPrograms(
    $first: Int
    $after: String
    $contentType: ContentType
  ) {
    getSavedData: getSavedDataV2(
      input: { first: $first, after: $after, contentType: $contentType }
    ) {
      edges {
        node {
          __typename
          ... on ProgramV2 {
            slug
          }
        }
      }
    }
  }
`;

export const GET_HISTORY_DATA = gql`
  ${classCardFragment}
  query GetHistoryData($first: Int, $after: String) {
    getHistoryData: getHistoryDataV2(input: { first: $first, after: $after }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          __typename
          ... on ProgramV2 {
            programSlug: slug
            progress {
              completedDate
            }
          }
          ... on Class {
            ...ClassCardData
          }
        }
      }
    }
  }
`;

export const GET_SAVED_DATA = gql`
  ${classCardFragment}
  query getSavedData($first: Int, $after: String) {
    getSavedDataV2: getSavedDataV2(input: { first: $first, after: $after }) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          __typename
          ... on ProgramV2 {
            programSlug: slug
          }
          ... on Class {
            ...ClassCardData
          }
        }
      }
    }
  }
`;

export const SEARCH_PROGRAMS_V2 = gql`
  ${CLASS_FIELDS}
  query SearchProgramsV2(
    $freeOnly: Boolean
    $inProgressOnly: Boolean
    $pagination: PaginationBody!
    $sort: SortBody
    $query: String!
    $filters: String
  ) {
    searchProgramsV2: searchProgramsV2(
      input: {
        inProgressOnly: $inProgressOnly
        freeOnly: $freeOnly
        pagination: $pagination
        sort: $sort
        query: $query
        filters: $filters
      }
    ) {
      programs {
        slug
        title
        level
        style
        totalSectionCount
        totalClassesCount
        averageSessionTime
        publishDate
        categories
        isSaved
        isFree
        classes(input: { isFree: $freeOnly }) {
          ...ClassFields
        }
      }
      pageInfo {
        page
        totalPages
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const PROFILE_QUERY = gql`
  ${CORE_PROFILE_FIELDS}
  query user {
    user: user {
      ...CoreProfileFields
    }
  }
`;

export const ME_QUERY = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  ${CORE_PLAN_FIELDS}
  query GetMe($first: Float) {
    me: me {
      id
      uid
      isActive
      hasExtendedTrial
      hasTrialed
      remainingClassUnlocks
      unlockedClasses {
        id
        isUnlocked
      }
      primaryPaymentSource {
        id
        gateway
        type
        card {
          lastFour
          brand
          fundingType
          object
        }
      }
      primarySubscription {
        ...CoreSubscriptionFields
        plan {
          ...CorePlanFields
          isSpecial
          isLegacy
          periodUnit
          platform
          priceInCents
          monthlyCostInCents
          provider {
            name
            id
          }
        }
        invoiceEstimate {
          amountDue
        }
        invoiceDue {
          id
          amountDue
          dunningAttemptsCount
          nextRetryAt
          dueDate
        }
        provider {
          name
        }
      }
      uploadedVideos(first: $first) {
        edges {
          node {
            id
            description
            thumbnailUrl
            playbackUrl
            uploadedAt
            uploadedBy {
              username
            }
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      scheduledSubscriptionChange {
        id
        nextBillingAt
        invoiceDue {
          amountDue
        }
        invoiceEstimate {
          amountDue
          total
        }
        plan {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  ${CORE_VIDEO_UPLOAD_FIELDS}
  query GetUserProfile($first: Float) {
    me {
      id
      uid
      username
      name
      photoURL
      totalLikesCount
      uploadedVideos(first: $first) {
        edges {
          node {
            ...CoreVideoUploadFields
          }
          cursor
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ACKNOWLEDGED_CONTENT = gql`
  query GetAcknowledgedContent {
    me {
      id
      uid
      progress {
        acknowledgedContent
      }
    }
  }
`;

export const UNLOCKED_CLASSES_QUERY = gql`
  ${CLASS_FIELDS}
  query GetUnlockedClasses {
    me: me {
      unlockedClasses {
        ...ClassFields
      }
    }
  }
`;

export const PLANS_QUERY = gql`
  ${CORE_PLAN_FIELDS}
  query plans($providerName: SubscriptionProvider!, $countryCode: String!) {
    plans: plans(
      input: { providerName: $providerName, countryCode: $countryCode }
    ) {
      ...CorePlanFields
      isBestValue
      isSubscribed
      monthlySavingsInPercent
      monthlyCostInCents
      annualSavingsInCents
      period
      periodUnit
      trialPeriod
      trialPeriodUnit
      priceInCents
      canTrial(
        input: { providerName: $providerName, countryCode: $countryCode }
      )
      trialEndTime(
        input: { providerName: $providerName, countryCode: $countryCode }
      )
      nextBillingAt(
        input: { providerName: $providerName, countryCode: $countryCode }
      )
      invoiceEstimate {
        amountDue
        creditsApplied
        total
        subTotal
      }
      provider {
        id
        name
      }
    }
  }
`;

export const CHECKOUT_ESTIMATE_QUERY = gql`
  query checkoutEstimate($planInfo: PlanInfoInput!, $couponId: String) {
    checkoutEstimate: checkoutEstimate(
      input: { planInfo: $planInfo, couponId: $couponId }
    ) {
      coupon {
        discountType
        discountDuration
        discountPercentage
        discountAmount
      }
      monthlyDiscountPlanPriceInCents
      discountPlanPriceInCents
      monthlySavingsAfterDiscountInPercent
    }
  }
`;
