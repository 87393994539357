export const FONT_SIZES = {
  H1: "32px",
  H1P5: "28px",
  H2: "24px",
  H2P5: "20px",
  H3: "18px",
  H4: "14px",
  H5: "12px",
  H6: "11px",
  P1: "14px",
  P2: "12px",
  P3: "10px",
  BODY_SMALL: "14px",
  CAPTION: "12px",
  BUTTON: "12px",
  SMALL_BUTTON: "14px",
} as const;

export const LETTER_SPACING = {
  H5: "0.12px",
  P3: "0.2px",
  BUTTON: "2px",
} as const;

export const FONT_WEIGHTS = {
  REGULAR: "normal",
  MEDIUM: "500",
  SEMI_BOLD: "600",
  BOLD: "bold",
} as const;

export const TEXT_TRANSFORM = {
  UPPERCASE: "uppercase",
} as const;
