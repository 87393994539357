import React from "react";
import env from "helpers/env";
import Img, { ImgProps } from "../Img";

export const Avatar = ({ alt = "Avatar", src, ...rest }: ImgProps) => {
  const defaultSrc = `${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=240`;

  const imageUrl =
  src ||
  `${process.env.EXPO_PUBLIC_ASSET_PATH}/default-avatar.png?w=240}`;

  return (
    <Img
      alt={alt}
      src={imageUrl}
      placeholderSrc={defaultSrc}
      borderRadius="50%"
      {...rest}
    />
  );
};

export default Avatar;
