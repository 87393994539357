import React from "react";
import { Link } from "react-router-dom";
import { useGetUserInstructorProfileBySlugPublicQuery } from "services/graphql";
import env from "helpers/env";
import styles from "./styles.module.scss";

interface Props {
  instructorSlug: string;
  height?: number;
  width?: number;
  showName?: boolean;
}

export function InstructorAvatar({
  instructorSlug,
  height,
  width,
  showName = true,
}: Props) {
  const { data, loading } = useGetUserInstructorProfileBySlugPublicQuery({
    variables: { slug: instructorSlug },
  });

  if (loading) {
    const size = width || height || 64;
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.avatarPlaceholder}
          style={{ width: size, height: size }}
        />
        {showName && <div className={styles.namePlaceholder} />}
      </div>
    );
  }

  const instructor = data?.instructorBySlugPublic;
  if (!instructor) {
    return null;
  }

  const imageUrl =
    instructor.userProfile?.photoURL ||
    instructor.avatarImageUrl ||
    `${env("PUBLIC_ASSET_PATH")}/choreographers/${instructorSlug}.png?w=120`;

  const displayName = instructor.userProfile?.name || instructor.name;
  const profileLink = instructor.userProfile?.username
    ? `/@${instructor.userProfile.username}`
    : `/library/instructors/${instructorSlug}`;

  return (
    <div className={styles.wrapper}>
      <Link to={profileLink} className={styles.link}>
        <img
          className={styles.avatar}
          src={imageUrl}
          alt={displayName}
          style={{ width: width || height || 64 }}
        />
      </Link>
      {showName && (
        <Link to={profileLink} className={styles.link}>
          <span className={styles.instructorName}>{displayName}</span>
        </Link>
      )}
    </div>
  );
}
