import React from "react";
import { useDispatch } from "react-redux";
import { DropdownOption } from "app/components/Dropdown";
import { Link } from "react-router-dom";
import { logout } from "modules/auth/actions";
import { Routes } from "constants/routes";

export const Links = ({
  isAnonymous,
  username,
}: {
  isAnonymous: boolean;
  username: string;
}) => {
  const dispatch = useDispatch();

  const canViewProfile = !isAnonymous && username;

  const signOut = () => {
    dispatch(logout());
  };

  return (
    <>
      {canViewProfile && (
        <Link to={`/@${username}`}>
          <DropdownOption text="View Profile" />
        </Link>
      )}
      {!isAnonymous && (
        <Link to={Routes.account__settings}>
          <DropdownOption text="Settings" />
        </Link>
      )}
      {!isAnonymous && (
        <Link to={Routes.achievements}>
          <DropdownOption text="Achievements" />
        </Link>
      )}
      {!isAnonymous && <DropdownOption onClick={signOut} text="Log Out" />}
    </>
  );
};
